<template>
  <div class="my-sales-page">
    <top-bar />

    <page-title v-bind="info" :image="flower1" />

    <n-loader :loading="$var('load')" />

    <div class="container">
      <n-tabs :data="tabs" name="tab" state>
        <template #tab="{item}">
          {{ item.title }}
        </template>
      </n-tabs>
    </div>

    <div class="container">
      <div v-if="$n.size(sales)" class="sales-list">
        <order-card v-for="order in sales" :key="order.id" :order="order" from-seller />
      </div>
      <div v-else-if="!$var('load')" class="page-message">
        <template v-if="!isArchive">У вас еще нет продаж</template>
        <template v-else>У вас еще нет архивных продаж</template>
      </div>
    </div>
  </div>
</template>

<script>
import flower1 from 'assets/flower1.png'
import OrderCard from '../components/OrderCard'

export default {
  name: 'Index',
  components: { OrderCard, },
  data() {
    return {
      flower1,
      sales: [],
      tabs: [
        { name: 'active', title: 'Мои продажи', },
        { name: 'archive', title: 'Архив', },
      ],
    }
  },
  computed: {
    isArchive() {
      return this.$route.query.tab === 'archive'
    },
    info() {
      return this.isArchive ? {
        title: 'Архив',
        description: 'В этом разделе отображается архив ваших продаж',
        button: 'Мои продажи',
        link: { query: { archive: undefined, }, },
      } : {
        title: 'Мои продажи',
        description: 'В этом разделе отображаются ваши товары, которые у вас купили',
        button: 'Архив',
        link: { query: { archive: null, }, },
      }
    },
  },
  watch: {
    isArchive() {
      this.load()
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.my.sales().filter({ status: this.isArchive ? 'not:formed' : 'formed', }).with('products').then((response) => {
        this.sales = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.my-sales-page {
  .wrap{
    position: relative;
  }
  .n-tabs {
    margin-bottom: 20px;
  }
}
</style>
